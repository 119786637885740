<template>
  <div class="page">
    <div class="head">
      <img class="full-img" mode="widthFix" src="https://www.2gooo.cn/uploads/wxmini/images/icon/yellowbg.png">
      <span class="title">{{ticketDetail.supplier_name}}</span>
      <span class="title o_status">{{ticketDetail.status_text}}</span>
    </div>
  
    <div class="cont">
      <div class="status-box status1">
        <div
            class="detail-box"
            v-for="(item, idx) in ticketDetail.goodsList"
            :key="item.goods_id"
        >
          <div class="left">
            <img class="full-img" mode="widthFix" :src="item.default_image">
          </div>
          <div class="right">
            <div class="title-box">
              <div class="title ellipsis2">{{item.abbreviation}}</div>
            </div>
            <div class="desc-box flexcenter">
              <div>价格:{{item.sale_price}}</div>
              <div>x{{item.qty}}</div>
            </div>
          </div>
        </div>
        <div class="detail-box" v-if="ticketDetail.bindGoods">
          <div class="left">
            <img class="full-img" mode="widthFix" :src="ticketDetail.bindGoods.default_image">
          </div>
          <div class="right">
            <div class="title-box">
              <div class="title ellipsis2">{{ticketDetail.bindGoods.goods_name}}</div>
            </div>
            <div class="desc-box flexcenter">
              <div>价格：{{ticketDetail.bindGoods.price}}</div>
              <div>x{{ticketDetail.bind_goods_num}}</div>
            </div>
          </div>
        </div>
      </div>
    
    
      <div class="price-box flexcenter">
        <span>订单金额</span>
        <span>￥{{ticketDetail.order_amount}}</span>
      </div>
    
      <div class="customer-box">
        <div class="subtitle">游客信息</div>
      
        <div class="item"
             v-for="(guest, idx) in ticketDetail.guestList"
             :key="guest.rec_id"
        >
          <!--TODO: 添加已改签、已退票等状态-->
          <div class="title">游客{{idx+1}}</div>
          <div>姓名：{{guest.user_name}}</div>
          <div>手机号：{{guest.user_mobile}}</div>
          <div>身份证号：{{guest.card_no}}</div>
          <div>门票日期：{{guest.ticket_date_text}}</div>
          <div>状态：{{guest.ticket_status_text}}</div>
          <div>票号：{{guest.ticket_code}}</div>
		  <div @click.stop="refundByTickets(guest.rec_id)"  v-if="guest.ticket_status==2&&today<guest.ticket_date" class="goRefund">申请退票</div>
        </div>
      </div>
    
      <div class="order-box">
        <div class="subtitle">订单信息</div>
      
        <div class="item">
          <div>创建时间：2021-01-22 18:36:29</div>
          <div>订单编号：{{ticketDetail.order_sn}}</div>
        </div>
		
      </div>
	  <div class="order-box" v-if="ticketDetail.supplier_id=='2'">
	    <div class="subtitle">入园须知</div>
	    <div class="item">
	      <div>北京环球影城游客下单成功后，请登陆“北京环球度假区”官方APP或微信小程序，首页“园区预约”通过证件号进行预约（必须）。园区营业时间为10:00-19:00，入园当天请务必携带身份证和口罩前往，祝您旅行愉快！</div>
	    </div>
	  </div>
	  
	  <div class="order-box" v-if="ticketDetail.supplier_id=='2'">
		<div class="subtitle">环球影城旅拍点击了解更多</div>
		<div class="item">
			<router-link to="/tour-subject?sid=5&title=产品列表&tid=100407,100408,100409" hover-class="navigator-hover">
				<img class="full-img" mode="widthFix" src="https://tugo-1257547782.cos.ap-beijing.myqcloud.com/uploads/weapp_res/other/lvpai_ad.jpeg">
			</router-link>
		</div>
	  </div>
	  
    </div>
  
    <div class="gap"></div>
  
    <div class="bottom flexcenter">
      <div style="width: 100%; text-align: center; font-size: 16px;">客服电话:13161071627</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'mytickets-detail',
    data() {
      return {
        // 0 正在出票；1已出票；2已完成；3已取消
        status: 0,
        statusMap: ['正在出票', '已出票', '已完成', '已取消'],
        loading:false,
        ticketDetail:[],
		today:0
      }
    },
    created() {
      this.getOrderDetail(this.$route.query.oid);
    },
    methods: {
      //获取商品信息数据
      getOrderDetail(oid){
        let that = this;
        this.post(
          this.API.tourOrderDetail,
          {oid:oid}
        ).then(response => {
          that.ticketDetail= response.data.detail;
		  that.today = response.data.today;
        })
      },
	  refundByTickets(rec_id){
		  if(rec_id==''||typeof(rec_id)=="undefined")
		  {
		    this.$toast("缺少门票参数");
		    return;
		  }
		  let that = this;
		  this.$dialog.confirm({ title: '提示',message: '确认需要退票嘛？如有其它问题可以致电客服，客服电话:13161071627'})
		  	.then(res => {
		  		that.post(that.API.refundByTickets,{recid:rec_id}).then(
		  			response => {
		  			if(response.data.err=='1'){
		  			  that.$toast(response.data.msg);
		  			}else{
		  			  that.$toast(response.data.msg);
		  			}
		  		})
		  	})
	  }
    }
  };
</script>

<style lang="scss" scoped>
  .page {
      /*background-color: #F5F5F5;*/
      min-height: 100%;
      font-size: 32px;
      line-height: 1.6;
      font-family: "PingFang SC";
  }
  
  .head {
      position: relative;
      font-size: 48px;
      font-weight: bold;
      color: #191919;
  }
  .head .title {
      position: absolute;
      left: 36px;
      top: 32px;
  }
  .head .o_status {
      position: absolute;
      left: auto;
      right: 20px;
      top: 32px;
  }
  
  .cont {
      position: relative;
      top: -124px;
      margin: 0 24px;
  }
  .cont .status-box {
      justify-content: space-between;
      margin-bottom: 48px;
      border-radius: 24px;
      box-shadow: 0 4px 14px #878787;
      font-size: 28px;
      color: #191919;
      padding:20px;
  }
  .cont .status1 {
      justify-content: center;
      background-color: #FFFFFF;
  }
  .cont .status2 {
      justify-content: center;
      height: 232px;
      background-color: #FFFFFF;
  }
  .cont .status2 .img {
      margin-right: 30px;
      width: 160px;
  }
  
  .cont .detail-box {
      display: flex;
      justify-content: space-between;
      border-bottom: 2px dotted #cccccc;
      margin-bottom: 20px;
  }
  .cont .detail-box .left {
      margin-right: 20px;
      width: 140px;
  }
  .cont .detail-box .right {
      flex: 1;
      width: 280px;
  }
  .cont .detail-box .right .title-box {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      color: #191919;
  }
  .cont .detail-box .right .title-box .title {
      width: 346px;
  }
  .cont .detail-box .right .desc-box {
      justify-content: space-between;
      font-size: 24px;
      margin: 16px 0;
      color: #777777;
  }
  .cont .discount-box {
      justify-content: space-between;
      margin-bottom: 32px;
      font-size: 24px;
      color: #191919;
  }
  .cont .discount-box .left .jian {
      display: inline-block;
      margin-right: 10px;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 4px;
      background-color: #F84F41;
      font-size: 22px;
      color: #FFFFFF;
  }
  .cont .discount-box .right {
      font-weight: 28px;
      color: #F84F41;
  }
  .cont .price-box {
      justify-content: space-between;
      margin-bottom: 36px;
      padding-bottom: 34px;
      font-size: 28px;
      font-weight: bold;
      color: #191919;
      border-bottom: 2px solid #EEEEEE;
  }
  
  .cont .customer-box {
      margin-bottom: 36px;
  }
  .cont .customer-box .subtitle {
      margin-bottom: 24px;
      font-size: 28px;
      color: #191919;
  }
  .cont .customer-box .item {
      margin-bottom: 32px;
      font-size: 26px;
      color: #777777;
      line-height: 50px;
      padding-bottom: 20px;
      border-bottom: 2px dotted #cccccc;
  }
  .cont .customer-box .item .title {
      font-size: 28px;
      color: #191919;
  }
  .cont .customer-box .item .action-box {
      justify-content: space-between;
  }
  .cont .customer-box .item .action-box .act {
      width: 160px;
      height: 56px;
      line-height: 56px;
      border: 2px solid #999999;
      border-radius: 29px;
      text-align: center;
      font-size: 28px;
      color: #777777;
  }
  .cont .customer-box .item .action-box .tui {
      margin-right: 24px;
  }
  
  .cont .order-box {
      margin-bottom: 36px;
      font-size: 26px;
      color: #777777;
      line-height: 50px;
  }
  .cont .order-box .subtitle {
      margin-bottom: 24px;
      font-size: 28px;
      color: #191919;
  }
  
  .gap {
      height: 24px;
      background-color: #F5F5F5;
  }
  
  .bottom {
      justify-content: space-between;
      height: 110px;
      background-color: #FFFFFF;
  }
  .bottom .cancel {
      margin-right: 24px;
      width: 200px;
      height: 56px;
      line-height: 56px;
      border: 2px solid #999999;
      border-radius: 29px;
      text-align: center;
      font-size: 28px;
      color: #777777;
  }
  .item .goRefund {
      width: 160px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border: 2px solid #FCC601;
      background:#FCC601;
      border-radius: 28px;
      font-size: 28px;
      color: #FFFFFF;
      display: inline-block;
      margin-left: 20px;
	  float: right;
	  margin-top: -60px;
  }
</style>
